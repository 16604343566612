import React, { useEffect, useState } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { BsTranslate } from "react-icons/bs";
import { useLanguage } from "../Contexts/LanguageContext";
import "./LanguageBtn.css";
import ReactCountryFlag from "react-country-flag";

function LanguageBtn({ toggleNav }) {
  const [isOpen, setIsOpen] = useState(false);
  const { changeLanguage, currentLanguage } = useLanguage();

  // toggle off when nav is closed
  // useEffect(() => {
  //   isNavOpen === false && setIsOpen(false);
  // }, [isNavOpen]);

  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
    setIsOpen(false);
    if (toggleNav) toggleNav();
  };
  return (
    <div className={`language-btn ${isOpen ? "open" : ""}`}>
      <button className='btn' onClick={() => setIsOpen(!isOpen)}>
        <BsTranslate className='icon' />
        <span className='en'>Language</span>|<span className='ar'>اللغة</span>
        <RiArrowDownSLine className='chevron' />
      </button>
      <ul className='menu'>
        <li>
          <button
            className='choice ar'
            onClick={() => handleLanguageChange("ar")}
            disabled={currentLanguage.code === "ar"}
          >
            <ReactCountryFlag countryCode='SA' svg style={{ width: "1.3em" }} />
            العربية
          </button>
        </li>
        <li>
          <button
            className='choice en'
            onClick={() => handleLanguageChange("en")}
            disabled={currentLanguage.code === "en"}
          >
            <ReactCountryFlag countryCode='GB' svg style={{ width: "1.3em" }} />
            English
          </button>
        </li>
      </ul>
    </div>
  );
}

export default LanguageBtn;
