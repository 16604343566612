import { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";
import { BiMailSend } from "react-icons/bi";
import { FaRegCopy } from "react-icons/fa";
import { FaCheck, FaXmark } from "react-icons/fa6";
import { FiGithub } from "react-icons/fi";
import { LuLoader2 } from "react-icons/lu";
import { MdOutlineEmail, MdOutlinePhone } from "react-icons/md";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import "./ContactSec.css";

function ContactSec() {
  const [formLoading, setFormLoading] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [inputInFocus, setInputInFocus] = useState(null);
  const [emailCopied, setEmailCopied] = useState(false);
  const [phoneCopied, setPhoneCopied] = useState(false);
  const [formValue, setFormValue] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [formMessage, setFormMessage] = useState({
    type: null,
    body: null,
  });
  const [inputError, setInputError] = useState({
    target: null,
    body: null,
  });

  const formRef = useRef(null);
  const nameRef = useRef(null);
  const emailRef = useRef(null);
  const messageRef = useRef(null);

  const { t } = useTranslation();

  const copyEmail = () => {
    navigator.clipboard.writeText("awafa116116@gmail.com");

    setPhoneCopied(false);
    setEmailCopied(true);
    setTimeout(() => {
      setEmailCopied(false);
    }, 3000);
  };
  const copyPhone = () => {
    navigator.clipboard.writeText("+966533981764");

    setEmailCopied(false);
    setPhoneCopied(true);
    setTimeout(() => {
      setPhoneCopied(false);
    }, 3000);
  };

  const onChangeHandler = (e) => {
    checkBtnDisabled();
    setFormValue({ ...formValue, [e.target.name]: e.target.value });
  };

  const checkBtnDisabled = () => {
    setInputError({
      target: null,
      body: null,
    });
    if (
      nameRef.current.value.length > 0 &&
      emailRef.current.value.length > 0 &&
      messageRef.current.value.length > 0
    ) {
      setBtnDisabled(false);
    } else {
      setBtnDisabled(true);
    }
  };

  const validateForm = () => {
    // reset error
    handleError(null, null);
    console.log(inputError);

    const emailPattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/g;
    if (!formValue.email.match(emailPattern)) {
      handleError("email", "Please enter a valid E-mail");
      return false;
    }

    return true;
  };

  const handleError = (target, body) => {
    setInputError({
      target,
      body,
    });

    if (target === "name") {
      nameRef.current.focus();
    } else if (target === "email") {
      emailRef.current.focus();
    } else {
      messageRef.current.focus();
    }
  };

  const updateFormMessage = (body, type) => {
    setFormMessage({
      type,
      body,
    });
    setTimeout(() => {
      setFormMessage({
        type: null,
        body: null,
      });
    }, 5000);
  };

  const sendEmail = async (e) => {
    e.preventDefault();
    if (formLoading) return;
    if (!validateForm) return;

    setFormLoading(true);
    setInputError({
      target: null,
      body: null,
    });

    const serviceId = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const templateId = process.env.REACT_APP_EMAILJS_TEMPLATE_ID;
    const publicKey = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    await emailjs
      .sendForm(serviceId, templateId, formRef.current, publicKey)
      .then(() => {
        updateFormMessage(t("contact_sec.form.message.success"), "success");
        nameRef.current.value = "";
        emailRef.current.value = "";
        messageRef.current.value = "";
        setFormValue({
          name: "",
          email: "",
          message: "",
        });
        setInputError({
          target: null,
          body: null,
        });
        setBtnDisabled(true);
        setFormLoading(false);
      })
      .catch((err) => {
        updateFormMessage(t("contact_sec.form.message.error"), "error");
        setFormLoading(false);
      });
  };

  return (
    <section className='sec contact-sec primary-sec' id='contact-sec'>
      <div className='wrapper-s'>
        {formMessage.body && (
          <div className={`form-message ${formMessage.type}`}>
            {formMessage.type === "success" ? (
              <FaCheck className='icon' />
            ) : (
              <FaXmark className='icon' />
            )}
            {formMessage.body}
          </div>
        )}
        <form className='form' ref={formRef} onSubmit={sendEmail}>
          <h2 className='sec-title'>{t("contact_sec.title")}</h2>
          <div className='input-container'>
            <label
              htmlFor='name'
              className={
                inputInFocus === "name" || formValue.name ? "active" : ""
              }
            >
              {t("contact_sec.form.labels.name")}
            </label>
            <input
              ref={nameRef}
              type='text'
              id='name'
              name='name'
              className={`input ${inputError.target === "name" ? "err" : ""}`}
              onChange={onChangeHandler}
              onFocus={() => setInputInFocus("name")}
              onBlur={() => setInputInFocus(null)}
            />
            {inputError.target === "name" && (
              <span className='input-err'>{inputError.body}</span>
            )}
          </div>
          <div className='input-container'>
            <label
              htmlFor='email'
              className={
                inputInFocus === "email" || formValue.email ? "active" : ""
              }
            >
              {t("contact_sec.form.labels.email")}
            </label>
            <input
              ref={emailRef}
              type='text'
              id='email'
              name='email'
              className={`input ${inputError.target === "email" ? "err" : ""}`}
              onChange={onChangeHandler}
              onFocus={() => setInputInFocus("email")}
              onBlur={() => setInputInFocus(null)}
            />
            {inputError.target === "email" && (
              <span className='input-err'>{inputError.body}</span>
            )}
          </div>
          <div className='input-container message'>
            <label
              htmlFor='message'
              className={
                inputInFocus === "message" || formValue.message ? "active" : ""
              }
            >
              {t("contact_sec.form.labels.message")}
            </label>
            <textarea
              ref={messageRef}
              type='text'
              id='message'
              name='message'
              className={`input ${
                inputError.target === "message" ? "err" : ""
              }`}
              onChange={onChangeHandler}
              onFocus={() => setInputInFocus("message")}
              onBlur={() => setInputInFocus(null)}
            />
            {inputError.target === "message" && (
              <span className='input-err'>{inputError.body}</span>
            )}
          </div>
          <button
            type='submit'
            className='submit-btn primary-btn'
            disabled={btnDisabled}
          >
            {formLoading ? (
              <LuLoader2 className='loader' />
            ) : (
              <>
                <BiMailSend className='icon' />
                {t("contact_sec.form.submit_btn")}
              </>
            )}
          </button>
          <div className='personal-constacts'>
            <div className='contact-field email'>
              <MdOutlineEmail className='icon' />
              <p className='contact'>awafa116116@gmail.com</p>
              <button className='copy-btn' type='button' onClick={copyEmail}>
                {emailCopied ? (
                  <FaCheck className='icon copied' />
                ) : (
                  <FaRegCopy className='icon' />
                )}
              </button>
            </div>
            <div className='contact-field phone'>
              <MdOutlinePhone className='icon' />
              <p className='contact'>+966 53 398 1764</p>
              <button className='copy-btn' type='button' onClick={copyPhone}>
                {phoneCopied ? (
                  <FaCheck className='icon copied' />
                ) : (
                  <FaRegCopy className='icon' />
                )}
              </button>
            </div>
            <a className='social-media-link' target='_blank'>
              <FaInstagram />
            </a>
            <a
              className='social-media-link'
              href='https://www.linkedin.com/in/mustafasaeeddev'
              target='_blank'
            >
              <FaLinkedinIn />
            </a>
            <a
              className='social-media-link'
              href='https://www.github.com/awafa116'
              target='_blank'
            >
              <FiGithub />
            </a>
          </div>
          <div className='auto-reply-fields' style={{ display: "none" }}>
            <input
              type='hidden'
              name='auto-reply-subject'
              defaultValue={t("contact_sec.form.auto_reply.subject")}
            />
            <input
              type='hidden'
              name='auto-reply-greeting'
              defaultValue={t("contact_sec.form.auto_reply.greeting", {
                name: formValue.name,
              })}
            />
            <input
              type='hidden'
              name='auto-reply-message'
              defaultValue={t("contact_sec.form.auto_reply.message")}
            />
            <input
              type='hidden'
              name='auto-reply-wishes'
              defaultValue={t("contact_sec.form.auto_reply.wishes")}
            />
            <input
              type='hidden'
              name='auto-reply-sign'
              defaultValue={t("contact_sec.form.auto_reply.sign")}
            />
          </div>
        </form>
      </div>
    </section>
  );
}

export default ContactSec;
