import enPlaceholderImg from "../assets/img/project-placeholder-en.svg";
import arPlaceholderImg from "../assets/img/project-placeholder-ar.svg";
import "./ProjectCard.css";
import { LuExternalLink, LuGithub } from "react-icons/lu";
import { FaRegCircleDot, FaCode } from "react-icons/fa6";
import { useTranslation } from "react-i18next";
import { useLanguage } from "../Contexts/LanguageContext";

function ProjectCard({ project }) {
  const {
    name,
    URL,
    git_repo,
    tech,
    status,
    thumbnail_path,
    translation_path,
    launch_date,
  } = project;
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();

  const techChips = tech.map((item, i) => (
    <span className='tech' key={i}>
      {item}
    </span>
  ));

  return (
    <article className='project-card'>
      <div className='img-container'>
        <img
          src={
            thumbnail_path
              ? thumbnail_path
              : currentLanguage.code === "ar"
              ? arPlaceholderImg
              : enPlaceholderImg
          }
          alt=''
          className='img'
        />
        <p
          className={`status ${status === "live" ? "live" : ""} ${
            status === "dev" ? "dev" : ""
          }`}
        >
          {currentLanguage.code === "en" && <span>• </span>}
          {t(`projects_sec.status.${status}`)}
          {currentLanguage.code === "ar" && <span> •</span>}
        </p>
      </div>
      <div className='tech-container'>
        {tech.length > 0 &&
          tech.map((item, i) => (
            <span className='tech' key={i}>
              {item}
            </span>
          ))}
      </div>
      <span className='launch-date'>{launch_date}</span>
      <div className='name-container'>
        <h3 className='name'>{name}</h3>
        {URL && (
          <a className='link' href={URL} target='_blank'>
            <LuExternalLink />
          </a>
        )}
        {git_repo && (
          <a className='link' href={git_repo} target='_blank'>
            <LuGithub />
          </a>
        )}
      </div>
    </article>
  );
}

export default ProjectCard;
