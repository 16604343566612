import "./Footer.css";
import logoImg from "../assets/img/logo.svg";
import { useTranslation } from "react-i18next";
import LanguageBtn from "./LanguageBtn";
import { FaRegHeart } from "react-icons/fa";

function Footer() {
  const { t } = useTranslation();
  return (
    <footer className='footer sec'>
      <div className='wrapper-l container'>
        <img src={logoImg} alt="Mustafa Saeed's Logo" className='logo' />
        <div className='footer-menu'>
          <div className='links-container'>
            <a className='footer-link' href='#hero-sec'>
              {t("navbar.links.home")}
            </a>
            <a className='footer-link' href='#projects-sec'>
              {t("navbar.links.work")}
            </a>
            <a className='footer-link' href='#about-sec'>
              {t("navbar.links.about")}
            </a>
            <a className='footer-link' href='#contact-sec'>
              {t("navbar.links.contact")}
            </a>
          </div>
          <LanguageBtn />
        </div>
        <p className='made-with-love'>
          built with <FaRegHeart /> by Mustafa Saeed{" "}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
