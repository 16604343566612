import React, { useEffect, useRef, useState } from "react";
import data from "../data.json";
import { useTranslation } from "react-i18next";
import "./QuoteSec.css";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import { BiSolidQuoteLeft, BiSolidQuoteRight } from "react-icons/bi";

import placeholder from "../assets/img/quote-placeholder.svg";
import { useLanguage } from "../Contexts/LanguageContext";

function QuoteSec() {
  const [currentSlide, setCurrrentSlide] = useState(0);
  const [isSliding, setIsSliding] = useState(false);
  const currentSlideRef = useRef(null);

  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();

  const prevSlide = () => {
    if (currentSlide === 0) return;
    if (isSliding) return;
    setIsSliding(true);

    currentSlideRef.current.classList.add("animate-out-prev");
    setTimeout(() => {
      setCurrrentSlide((old) => old - 1);
      currentSlideRef.current.classList.remove("animate-out-prev");
      currentSlideRef.current.classList.add("animate-in-prev");
    }, 300);

    setTimeout(() => {
      currentSlideRef.current.classList.remove("animate-in-prev");
      setIsSliding(false);
    }, 600);
  };

  const nextSlide = () => {
    if (currentSlide === data.quotes.length - 1) return;
    if (isSliding) return;

    setIsSliding(true);

    currentSlideRef.current.classList.add("animate-out-next");
    setTimeout(() => {
      setCurrrentSlide((old) => old + 1);
      currentSlideRef.current.classList.remove("animate-out-next");
      currentSlideRef.current.classList.add("animate-in-next");
    }, 300);
    setTimeout(() => {
      currentSlideRef.current.classList.remove("animate-in-next");
      setIsSliding(false);
    }, 600);
  };

  const slideTo = (i) => {
    if (currentSlide === i) return;
    setIsSliding(true);

    if (currentSlide < i) {
      currentSlideRef.current.classList.add("animate-out-next");
      setTimeout(() => {
        setCurrrentSlide(i);
        currentSlideRef.current.classList.remove("animate-out-next");
        currentSlideRef.current.classList.add("animate-in-next");
      }, 300);
      setTimeout(() => {
        currentSlideRef.current.classList.remove("animate-in-next");
      }, 600);
    } else {
      currentSlideRef.current.classList.add("animate-out-prev");
      setTimeout(() => {
        setCurrrentSlide(i);
        currentSlideRef.current.classList.remove("animate-out-prev");
        currentSlideRef.current.classList.add("animate-in-prev");
      }, 300);
      setTimeout(() => {
        currentSlideRef.current.classList.remove("animate-in-prev");
        setIsSliding(false);
      }, 600);
    }
  };

  const elQuotes = data.quotes.map((quote) => {
    return (
      <div className='quote'>
        <div className='author-container'>
          <img
            src={quote.portraitURL ? quote.portraitURL : placeholder}
            alt={`a picture of ${quote.author}`}
            className='img'
          />
          <div className='author-text'>
            <h3 className='author'>{t(`${quote.translation_path}.author`)}</h3>
            <p className='occupation'>
              {t(`${quote.translation_path}.occupation`)}
            </p>
          </div>
        </div>
        <article className='body'>
          {t(`${quote.translation_path}.body`)}
        </article>
      </div>
    );
  });
  return (
    <section className='sec quote-sec' id='quote-sec'>
      <div className='wrapper-s'>
        <div className='slider-container'>
          <div className='slider'>
            {/* <div className='prev-slide'>
              {elQuotes[currentSlide - 1] && elQuotes[currentSlide - 1]}
            </div> */}
            <div className='current-slide' ref={currentSlideRef}>
              {elQuotes[currentSlide]}
            </div>
            {/* <div className='next-slide'>
              {elQuotes[currentSlide + 1] && elQuotes[currentSlide + 1]}
            </div> */}
          </div>

          <div className='slider-indicators'>
            <button
              className='btn prev'
              onClick={prevSlide}
              disabled={currentSlide === 0}
            >
              {currentLanguage.code === "ar" ? (
                <MdOutlineChevronRight />
              ) : (
                <MdOutlineChevronLeft />
              )}
            </button>
            {data.quotes.map((quote, i) => {
              return (
                <div
                  className={`dot ${i === currentSlide ? "active" : ""}`}
                  onClick={() => slideTo(i)}
                ></div>
              );
            })}
            <button
              className='btn next'
              onClick={nextSlide}
              disabled={currentSlide === data.quotes.length - 1}
            >
              {currentLanguage.code === "ar" ? (
                <MdOutlineChevronLeft />
              ) : (
                <MdOutlineChevronRight />
              )}
            </button>
          </div>
        </div>
      </div>
      <BiSolidQuoteLeft className='quotation-mark left' />
      <BiSolidQuoteRight className='quotation-mark right' />
    </section>
  );
}

export default QuoteSec;
