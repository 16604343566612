import { LanguageProvider } from "./Contexts/LanguageContext";

import "./App.css";
import Navbar from "./Components/Navbar";
import HeroSec from "./Components/HeroSec";
import ProjectsSec from "./Components/ProjectsSec";
import AboutSec from "./Components/AboutSec";
import ToolsSec from "./Components/ToolsSec";
import QuoteSec from "./Components/QuoteSec";
import ContactSec from "./Components/ContactSec";
import Footer from "./Components/Footer";

function App() {
  return (
    <LanguageProvider>
      <Navbar />
      <HeroSec />
      <ProjectsSec />
      <QuoteSec />
      <AboutSec />
      <ToolsSec />
      <ContactSec />
      <Footer />
    </LanguageProvider>
  );
}

export default App;
