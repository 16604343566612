import React, { createContext, useContext, useEffect, useState } from "react";
import cookies from "js-cookie";
import i18n from "../i18n";

const LanguageContext = createContext();

export const useLanguage = () => {
  return useContext(LanguageContext);
};

export function LanguageProvider({ children }) {
  const [languages, setLanguages] = useState([
    { code: "en", name: "English", flag_code: "gb", dir: "ltr" },
    { code: "ar", name: "العربية", flag_code: "sa", dir: "rtl" },
  ]);
  const [currentLanguage, setCurrentLanguage] = useState(null);
  const [currentLanguageCode, setCurrentLanguageCode] = useState("en");

  useEffect(() => {
    setCurrentLanguageCode(cookies.get("i18next") || "en");
  }, []);

  useEffect(() => {
    const currentLanguage = languages.find(
      (lang) => lang.code === currentLanguageCode
    );
    setCurrentLanguage(currentLanguage);
    document.querySelector("body").dir = currentLanguage.dir;
    document.querySelector("html").lang = currentLanguage.code;
  }, [currentLanguageCode]);

  const changeLanguage = (lng) => {
    if (lng === "swap") {
      const newLanguage = currentLanguage.code === "ar" ? "en" : "ar";
      i18n.changeLanguage(newLanguage);
    } else {
      i18n.changeLanguage(lng);
    }
    setCurrentLanguageCode(cookies.get("i18next") || "en");
  };

  const value = {
    changeLanguage,
    currentLanguage,
  };

  return (
    <LanguageContext.Provider value={value}>
      {currentLanguage && children}
    </LanguageContext.Provider>
  );
}
