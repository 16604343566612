import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ProjectCard from "./ProjectCard";
import { BsStars, BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { RiArrowDownSLine } from "react-icons/ri";
import { FaFilter } from "react-icons/fa";
import data from "../data.json";
import "./ProjectsSec.css";
import { useLanguage } from "../Contexts/LanguageContext";

function ProjectsSec() {
  const { t } = useTranslation();
  const { currentLanguage } = useLanguage();
  const [projectsData, setProjectsData] = useState();
  const [filter, setFilter] = useState("all");
  const [isFilterMenuOpen, setIsFilterMenuOpen] = useState(false);

  useEffect(() => {
    const projects = data.projects;
    const filteredProjects = projects.filter(
      (project) => project.type === filter
    );

    if (filter === "all") {
      setProjectsData(projects);
    } else {
      setProjectsData(filteredProjects);
    }
  }, [filter]);

  const updateFilter = (filter) => {
    setFilter(filter);
    setIsFilterMenuOpen(false);
  };

  const toggleFilterMenu = () => {
    setIsFilterMenuOpen(!isFilterMenuOpen);
  };

  const elProjects =
    projectsData &&
    projectsData.map((project) => {
      return <ProjectCard key={project.id} project={project} />;
    });
  return (
    <section className='sec projects-sec primary-sec' id='projects-sec'>
      <div className='wrapper-s'>
        <div className='title-container'>
          <h2 className='sec-title'>{t("projects_sec.title")}</h2>
          <div className={`filter-container ${isFilterMenuOpen ? "open" : ""}`}>
            <button className='btn' onClick={toggleFilterMenu}>
              <FaFilter className='icon' />
              {t(`projects_sec.filters.${filter}`)}
              <RiArrowDownSLine className='chevron' />
            </button>
            <ul className={`menu ${isFilterMenuOpen ? "open" : ""}`}>
              <li>
                <button
                  className='choice'
                  onClick={() => updateFilter("all")}
                  disabled={filter === "all"}
                >
                  {t("projects_sec.filters.all")}
                </button>
              </li>
              <li>
                <button
                  className='choice'
                  onClick={() => updateFilter("web_apps")}
                  disabled={filter === "web_apps"}
                >
                  {t("projects_sec.filters.web_apps")}
                </button>
              </li>
              <li>
                <button
                  className='choice'
                  onClick={() => updateFilter("websites")}
                  disabled={filter === "websites"}
                >
                  {t("projects_sec.filters.websites")}
                </button>
              </li>
              <li>
                <button
                  className='choice'
                  onClick={() => updateFilter("games")}
                  disabled={filter === "games"}
                >
                  {t("projects_sec.filters.games")}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className='projects-grid'>
          {elProjects}
          <div className='article projects-cta-card'>
            <p className='info'>
              {t("projects_sec.cta_card.info")}
              <BsStars />
            </p>
            <a className='link primary-btn' href='#contact-sec'>
              {t("projects_sec.cta_card.cta")}
              {currentLanguage.code === "ar" ? (
                <BsArrowLeft />
              ) : (
                <BsArrowRight />
              )}
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ProjectsSec;
