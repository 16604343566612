import { useTranslation } from "react-i18next";
import "./HeroSec.css";
import { MdOutlineHandshake } from "react-icons/md";
import { FaRegEye } from "react-icons/fa";
import { useEffect } from "react";

function HeroSec() {
  const { t } = useTranslation();

  const controlLight = () => {
    const heroSec = document.getElementById("hero-sec");
    const gridLight = document.getElementById("grid-light");
    const spotLight = document.getElementById("spot-light");

    heroSec.onmousemove = (e) => {
      const x = e.clientX - spotLight.offsetWidth / 2;
      const y = e.clientY - spotLight.offsetHeight / 2;

      const keyframes = {
        transform: `translate(${x}px, ${y}px)`,
      };

      spotLight.animate(keyframes, {
        duration: 300,
        fill: "forwards",
      });
    };
    heroSec.addEventListener("mouseleave", (e) => {
      const keyframes = {
        transform: `translate(100%, 100%)`,
      };

      spotLight.animate(keyframes, {
        duration: 300,
        fill: "forwards",
      });
    });
  };

  useEffect(() => {
    controlLight();
  }, []);
  return (
    <section className='sec hero-sec primary-sec' id='hero-sec'>
      <div className='text-container'>
        <h1 className='title'>{t("hero_sec.title")}</h1>
        <p className='subtitle'>{t("hero_sec.subtitle")}</p>
        <div className='cta-container'>
          <a className='primary-btn' href='#projects-sec'>
            <FaRegEye className='icon' />
            {t("hero_sec.primary_cta")}
          </a>
          <a className='secondary-btn' href='#contact-sec'>
            <MdOutlineHandshake className='icon' />
            {t("hero_sec.secondary_cta")}
          </a>
        </div>
      </div>
      <div className='grid-container'>
        <div className='spot-light' id='spot-light'></div>
        <div className='grid-light' id='grid-light'></div>
        <div className='grid' id='grid'></div>
      </div>
    </section>
  );
}

export default HeroSec;
