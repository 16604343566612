import { useTranslation } from "react-i18next";
import "./AboutSec.css";
import aboutImg from "../assets/img/about-img.png";
import { FaInstagram, FaLinkedinIn } from "react-icons/fa6";
import { FiGithub } from "react-icons/fi";
import { LuGraduationCap } from "react-icons/lu";
import {
  MdOutlineWorkOutline,
  MdOutlineQuestionMark,
  MdOutlineFileDownload,
} from "react-icons/md";
import data from "../data.json";
import resumeFile from "../assets/files/resume.pdf";

function AboutSec() {
  const { t } = useTranslation();

  const elCareer = data.career.map((item) => {
    return (
      <div className='career'>
        <div className={`icon ${item.type}`}>
          {item.type === "graduation" && <LuGraduationCap />}
          {item.type === "work" && <MdOutlineWorkOutline />}
          {item.type === "preparing" && <MdOutlineQuestionMark />}
        </div>
        <div className='text-container'>
          <h3 className='name'>{t(`${item.translation_path}.name`)}</h3>
          {item.place && (
            <p className='place'>{t(`${item.translation_path}.place`)}</p>
          )}
          {item.start_date && item.end_date && (
            <p className='duration'>{`${item.start_date} - ${item.end_date}`}</p>
          )}
        </div>
      </div>
    );
  });
  return (
    <section className='sec about-sec primary-sec' id='about-sec'>
      <div className='wrapper-s'>
        <div className='about-content'>
          <div className='img-container'>
            <img src={aboutImg} alt="mustafa saeed's portrait" />
            <div className='social-media'>
              <a className='link' target='_blank'>
                <FaInstagram />
              </a>
              <a
                className='link'
                href='https://www.linkedin.com/in/mustafasaeeddev'
                target='_blank'
              >
                <FaLinkedinIn />
              </a>
              <a
                className='link'
                href='https://github.com/awafa116'
                target='_blank'
              >
                <FiGithub />
              </a>
            </div>
            <div className='back-square'></div>
          </div>
          <div className='content-container'>
            <h2 className='sec-title'>{t("about_sec.who_am_i")}</h2>
            <p className='info'>{t("about_sec.who_am_i_info")}</p>
            <h2 className='sec-title'>{t("about_sec.what_can_i_do")}</h2>
            <p className='info'>{t("about_sec.what_can_i_do_info")}</p>
            <h2 className='sec-title career'>
              {t("about_sec.my_career_path")}
            </h2>
            <div className='career-container'>{elCareer}</div>
            <div className='about-cta'>
              <span className='question'>{t("about_sec.question")}</span>
              <a
                className='primary-btn'
                href={resumeFile}
                download="Mustafa Saeed's Resume"
              >
                <MdOutlineFileDownload />
                {t("about_sec.cta")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutSec;
