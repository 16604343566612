import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Suspense fallback='loading...'>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Suspense>
);
