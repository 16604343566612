import { useTranslation } from "react-i18next";
import "./ToolsSec.css";
import { useState } from "react";
import javascriptImg from "../assets/icons/javascript.svg";
import htmlImg from "../assets/icons/html.svg";
import cssImg from "../assets/icons/css.svg";
import reactImg from "../assets/icons/react.svg";
import firebaseImg from "../assets/icons/firebase.svg";
import gitImg from "../assets/icons/git.svg";
import sassImg from "../assets/icons/sass.svg";
import gsapImg from "../assets/icons/gsap.svg";
import tailwindImg from "../assets/icons/tailwind.svg";
import postmanImg from "../assets/icons/postman.svg";
import figmaImg from "../assets/icons/figma.svg";

function ToolsSec() {
  const { t } = useTranslation();
  const [tools, setTools] = useState([
    {
      name: "Javascript",
      img: javascriptImg,
    },
    {
      name: "HTML",
      img: htmlImg,
    },
    {
      name: "CSS",
      img: cssImg,
    },
    {
      name: "React.js",
      img: reactImg,
    },
    {
      name: "Firebase",
      img: firebaseImg,
    },
    {
      name: "Git",
      img: gitImg,
    },
    {
      name: "Sass",
      img: sassImg,
    },
    {
      name: "GSAP",
      img: gsapImg,
    },
    {
      name: "Tailwind",
      img: tailwindImg,
    },
    {
      name: "Postman",
      img: postmanImg,
    },
    {
      name: "Figma",
      img: figmaImg,
    },
  ]);

  const elTools = tools.map((tool) => {
    return (
      <div className='tool'>
        <img src={tool.img} alt={`${tool.name} icon`} className='img' />
        <span className='name'>{tool.name}</span>
      </div>
    );
  });
  return (
    <section className='sec tools-sec' id='tools-sec'>
      <div className='wrapper-s'>
        <h2 className='sec-title'>{t("tools_sec.title")}</h2>
        <div className='tools-grid'>
          {elTools}
          <div className='tool adding'>
            <span className='name'>{t("tools_sec.adding_card")}</span>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ToolsSec;
