// Navbar.jsx
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  RiHomeLine,
  RiInformationLine,
  RiMailOpenLine,
  RiCodeSSlashLine,
  RiMenu4Fill,
  RiCloseLine,
} from "react-icons/ri";

import logoImg from "../assets/img/logo.svg";
import "./Navbar.css";
import LanguageBtn from "./LanguageBtn";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("hero-sec");

  // apply nav effect on document scroll
  useEffect(() => {
    document.addEventListener("scroll", () => {
      const scrollY = window.scrollY;

      // add background for nav on scroll
      if (scrollY > 20) {
        document.getElementById("nav-bg").classList.add("visible");
      } else {
        document.getElementById("nav-bg").classList.remove("visible");
      }

      // update active link on scroll
      const sections = document.querySelectorAll(".primary-sec");
      sections.forEach((sec) => {
        const secOffset = sec.offsetTop - 100;
        const secId = sec.getAttribute("id");
        if (scrollY >= secOffset) {
          setActiveLink(secId);
        }
      });
    });
  }, []);

  // toggle the nav menu
  const toggleNav = () => {
    setIsOpen(!isOpen);
  };

  const handleNavigation = (link) => {
    setActiveLink(link);
    setIsOpen(false);
  };
  const { t } = useTranslation();
  return (
    <header className='header'>
      <div className='wrapper-l'>
        <nav className='navbar'>
          <img src={logoImg} alt='mustafa saeed logo' className='logo' />
          <button className='nav-burger' onClick={toggleNav}>
            {isOpen ? <RiCloseLine /> : <RiMenu4Fill />}
          </button>
          <div className='nav-bg' id='nav-bg'></div>
          <div className={`nav-menu ${isOpen ? "open" : ""}`}>
            <div className='links-container'>
              <a
                onClick={() => handleNavigation("hero-sec")}
                className={`nav-link ${
                  activeLink === "hero-sec" ? "active" : ""
                }`}
                href='#hero-sec'
              >
                <RiHomeLine className='icon' />
                {t("navbar.links.home")}
              </a>
              <a
                onClick={() => handleNavigation("projects-sec")}
                className={`nav-link ${
                  activeLink === "projects-sec" ? "active" : ""
                }`}
                href='#projects-sec'
              >
                <RiCodeSSlashLine className='icon' />
                {t("navbar.links.work")}
              </a>
              <a
                onClick={() => handleNavigation("about-sec")}
                className={`nav-link ${
                  activeLink === "about-sec" ? "active" : ""
                }`}
                href='#about-sec'
              >
                <RiInformationLine className='icon' />
                {t("navbar.links.about")}
              </a>
              <a
                onClick={() => handleNavigation("contact-sec")}
                className={`nav-link ${
                  activeLink === "contact-sec" ? "active" : ""
                }`}
                href='#contact-sec'
              >
                <RiMailOpenLine className='icon' />
                {t("navbar.links.contact")}
              </a>
            </div>
            <div className='cta-container'>
              <LanguageBtn toggleNav={toggleNav} isNavOpen={isOpen} />
            </div>
          </div>
        </nav>
      </div>
    </header>
  );
};

export default Navbar;
